import {
  AppSessionCellId,
  AppSessionId,
  DateTimeString,
  KernelId,
  ParameterValueId,
  SharedFilterSessionStateId,
  asciiCompare,
  getDateTimeString,
} from "@hex/common";
import {
  EntityState,
  PayloadAction,
  createDraftSafeSelector,
  createEntityAdapter,
  createReducer,
  createSlice,
} from "@reduxjs/toolkit";
import { castDraft } from "immer";
import { memoize, omitBy } from "lodash";

import {
  AppSessionCellMpFragment,
  AppSessionFileMpFragment,
  AppSessionMpFragment,
  KernelMpFragment,
  ParameterValueMpFragment,
  ScopeItemFragment,
} from "../../appsession-multiplayer/AppSessionMPModel.generated";
import { RootState } from "../store";
import {
  getSelectorsForEntityState,
  getSelectorsForEntityStateWithSoftDelete,
} from "../utils/entityAdapterSelectorCreator";
import { KeysOfUnion, assertNonNull } from "../utils/types";

//#region action helper types
type WithAppSessionId<T> = {
  appSessionId: AppSessionId;
  data: T;
};
type AppSessionAction<T> = PayloadAction<WithAppSessionId<T>>;
type SetFieldAction<T> = AppSessionAction<{
  key: keyof NonNullable<T>;
  value: unknown;
}>;
type SetEntityFieldPayload<T extends { id: string }> = {
  entityId: T["id"];
  key: keyof NonNullable<T>;
  value: unknown;
};
type SetEntityFieldAction<T extends { id: string }> = AppSessionAction<
  SetEntityFieldPayload<T>
>;

//#region types of data in redux store

// should we normalize out outputs?
// Also we don't do any automatic filtering of outputs with deletedDate set as things stand right now.
export type AppSessionCellMP = Omit<
  AppSessionCellMpFragment,
  | "chartCellSessionState"
  | "displayTableSessionState"
  | "filterCellSessionState"
>;
// not an actual top-level type in our redux store, but we export it here for convenience
export type OutputMP = AppSessionCellMP["outputs"][number];
export type ChartCellSessionStateMP = NonNullable<
  AppSessionCellMpFragment["chartCellSessionState"]
>;
export type DisplayTableSessionStateMP = NonNullable<
  AppSessionCellMpFragment["displayTableSessionState"]
>;
export type FilterCellSessionStateMP = NonNullable<
  AppSessionCellMpFragment["filterCellSessionState"]
>;
export type KernelMP = KernelMpFragment;
export type ParameterValueMP = ParameterValueMpFragment;
export type ScopeItemMP = ScopeItemFragment;
export type SharedFilterSessionStateMP = NonNullable<
  AppSessionMpFragment["sharedFilterSessionStates"]
>[number];
export type AppSessionMP = Omit<
  AppSessionMpFragment,
  "appSessionCells" | "kernel" | "parameterValues" | "scope" | "files"
>;
export type AppSessionFileMP = AppSessionFileMpFragment;

//#region adapters for normalization of lists of elements
const appSessionCellAdapter = createEntityAdapter<AppSessionCellMP>({
  selectId: (cell) => cell.id,
  sortComparer: (a, b) => asciiCompare(a.id, b.id),
});
const chartCellSessionStateAdapter =
  createEntityAdapter<ChartCellSessionStateMP>({
    // unlike most of our types, we don't key off of the direct id
    selectId: (table) => table.appSessionCellId,
  });
const displayTableSessionStateAdapter =
  createEntityAdapter<DisplayTableSessionStateMP>({
    // unlike most of our types, we don't key off of the direct id
    selectId: (table) => table.appSessionCellId,
  });
const filterCellSessionStateAdapter =
  createEntityAdapter<FilterCellSessionStateMP>({
    // unlike most of our types, we don't key off of the direct id
    selectId: (table) => table.appSessionCellId,
  });
const parameterValueAdapter = createEntityAdapter<ParameterValueMP>({
  selectId: (par) => par.id,
  sortComparer: (a, b) => asciiCompare(a.id, b.id),
});
const scopeAdapter = createEntityAdapter<ScopeItemMP>({
  selectId: (scope) => scope.name,
  sortComparer: (a, b) => asciiCompare(a.name, b.name),
});
const sharedFilterSessionStateAdapter =
  createEntityAdapter<SharedFilterSessionStateMP>({
    selectId: (sharedFilterSessionState) => sharedFilterSessionState.id,
  });
const appSessionFilesAdapter = createEntityAdapter<AppSessionFileMP>({
  selectId: (file) => file.id,
  sortComparer: (a, b) => asciiCompare(a.id, b.id),
});

//#region reducer for data for a single AppSessionMP instance
export type AppSessionMPValue = {
  appSession: AppSessionMP;
  appSessionCells: EntityState<AppSessionCellMP>;
  chartCellSessionStates: EntityState<ChartCellSessionStateMP>;
  displayTableSessionStates: EntityState<DisplayTableSessionStateMP>;
  filterCellSessionStates: EntityState<FilterCellSessionStateMP>;
  sharedFilterSessionStates: EntityState<SharedFilterSessionStateMP>;
  kernel: KernelMP | null;
  parameterValues: EntityState<ParameterValueMP>;
  scope: EntityState<ScopeItemMP>;
  files: EntityState<AppSessionFileMP>;
};

const appSessionMPValueSlice = createSlice({
  name: "appSessionMPValue",
  // This initial state is essentially meaningless,
  // Since this reducer is only called as a function directly by `appSessionMPReducer` and
  // we always should have a `initializeFromAppSessionMPData` action to really initialize things.
  initialState: null as unknown as AppSessionMPValue,
  reducers: {
    initializeFromAppSessionMPData(
      _state,
      action: AppSessionAction<AppSessionMpFragment>,
    ) {
      const {
        appSessionCells: rawAppSessionCells,
        files,
        kernel,
        parameterValues,
        scope,
        ...appSessionData
      } = action.payload.data;

      const appSessionCells = rawAppSessionCells.map(
        ({
          chartCellSessionState,
          displayTableSessionState,
          filterCellSessionState,
          ...cell
        }) => cell,
      );

      const chartCellSessionStates = rawAppSessionCells.flatMap((cell) =>
        cell.chartCellSessionState == null ? [] : [cell.chartCellSessionState],
      );

      const displayTableSessionStates = rawAppSessionCells.flatMap((cell) =>
        cell.displayTableSessionState == null
          ? []
          : [cell.displayTableSessionState],
      );

      const filterCellSessionStates = rawAppSessionCells.flatMap((cell) =>
        cell.filterCellSessionState == null
          ? []
          : [cell.filterCellSessionState],
      );

      return {
        appSession: appSessionData,
        appSessionCells: appSessionCellAdapter.setAll(
          appSessionCellAdapter.getInitialState(),
          appSessionCells,
        ),
        chartCellSessionStates: chartCellSessionStateAdapter.setAll(
          chartCellSessionStateAdapter.getInitialState(),
          chartCellSessionStates,
        ),
        displayTableSessionStates: displayTableSessionStateAdapter.setAll(
          displayTableSessionStateAdapter.getInitialState(),
          displayTableSessionStates,
        ),
        filterCellSessionStates: filterCellSessionStateAdapter.setAll(
          filterCellSessionStateAdapter.getInitialState(),
          filterCellSessionStates,
        ),
        kernel: kernel,
        parameterValues: parameterValueAdapter.setAll(
          parameterValueAdapter.getInitialState(),
          parameterValues,
        ),
        scope: scopeAdapter.setAll(scopeAdapter.getInitialState(), scope),
        exploreDialog: {
          showSql: false,
        },
        sharedFilterSessionStates: sharedFilterSessionStateAdapter.setAll(
          sharedFilterSessionStateAdapter.getInitialState(),
          appSessionData.sharedFilterSessionStates,
        ),
        files: appSessionFilesAdapter.setAll(
          appSessionFilesAdapter.getInitialState(),
          files ?? [],
        ),
      };
    },
    // app session actions
    setAppSessionField(state, action: SetFieldAction<AppSessionMP>) {
      const appSession: AppSessionMP = state.appSession;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (appSession as Record<string, any>)[action.payload.data.key] =
        action.payload.data.value;
    },
    clearOutputs(
      state,
      {
        payload: { data },
      }: AppSessionAction<{
        deletedDate: DateTimeString;
      }>,
    ) {
      const appSessionCells = state.appSessionCells.entities;
      for (const [_id, appSessionCell] of Object.entries(appSessionCells)) {
        appSessionCell?.outputs.forEach(
          (output) => (output.deletedDate = data.deletedDate),
        );
      }
    },
    // app session cell actions
    upsertAppSessionCell(state, action: AppSessionAction<AppSessionCellMP>) {
      appSessionCellAdapter.upsertOne(
        state.appSessionCells,
        action.payload.data,
      );
    },
    setAppSessionCellField(
      state,
      { payload: { data } }: SetEntityFieldAction<AppSessionCellMP>,
    ) {
      appSessionCellAdapter.updateOne(state.appSessionCells, {
        id: data.entityId,
        changes: { [data.key]: data.value },
      });
    },
    deleteAppSessionCell(
      state,
      {
        payload: { data },
      }: AppSessionAction<{
        appSessionCellId: AppSessionCellId;
        deletedDate: DateTimeString;
      }>,
    ) {
      appSessionCellAdapter.updateOne(state.appSessionCells, {
        id: data.appSessionCellId,
        changes: {
          deletedDate: data.deletedDate,
        },
      });
    },
    // app session cell output actions
    addOutputToAppSessionCell(
      state,
      {
        payload: { data },
      }: AppSessionAction<{
        appSessionCellId: AppSessionCellId;
        output: AppSessionCellMP["outputs"][number];
      }>,
    ) {
      const appSessionCell =
        state.appSessionCells.entities[data.appSessionCellId];

      if (appSessionCell == null) {
        throw new Error("Can't add an output for a missing app session cell");
      }

      // no-op if cell already exists
      if (appSessionCell.outputs.find((o) => o.id === data.output.id) == null) {
        appSessionCell.outputs.push(castDraft(data.output));
      }
    },
    updateOutputFrontendOutputContents(
      state,
      {
        payload: { data },
      }: AppSessionAction<{
        appSessionCellId: AppSessionCellId;
        outputId: string;
        contents: AppSessionCellMP["outputs"][number]["frontendOutputContents"];
      }>,
    ) {
      const appSessionCell =
        state.appSessionCells.entities[data.appSessionCellId];
      const output = appSessionCell?.outputs.find(
        (o) => o.id === data.outputId,
      );

      if (output == null) {
        throw new Error(
          `Can't find output ${data.outputId} for app session cell ${data.appSessionCellId}`,
        );
      }

      output.frontendOutputContents = [...data.contents];
    },
    hardDeleteOutput(
      state,
      {
        payload: { data },
      }: AppSessionAction<{
        appSessionCellId: AppSessionCellId;
        outputId: string;
      }>,
    ) {
      const outputs =
        state.appSessionCells.entities[data.appSessionCellId]?.outputs;
      const idx = outputs?.findIndex((o) => o.id === data.outputId);

      if (idx != null && idx !== -1) {
        outputs?.splice(idx, 1);
      }
    },
    // chart cell session state actions
    setChartCellSessionStateField(
      state,
      {
        payload: { data },
      }: AppSessionAction<{
        appSessionCellId: AppSessionCellId;
        key: KeysOfUnion<ChartCellSessionStateMP>;
        value: unknown;
      }>,
    ) {
      chartCellSessionStateAdapter.updateOne(state.chartCellSessionStates, {
        id: data.appSessionCellId,
        changes: { [data.key]: data.value },
      });
    },
    // display table session state actions
    upsertDisplayTableSessionState(
      state,
      action: AppSessionAction<DisplayTableSessionStateMP>,
    ) {
      displayTableSessionStateAdapter.upsertOne(
        state.displayTableSessionStates,
        action.payload.data,
      );
    },
    setDisplayTableSessionStateField(
      state,
      {
        payload: { data },
      }: AppSessionAction<{
        appSessionCellId: AppSessionCellId;
        key: KeysOfUnion<DisplayTableSessionStateMP>;
        value: unknown;
      }>,
    ) {
      displayTableSessionStateAdapter.updateOne(
        state.displayTableSessionStates,
        {
          id: data.appSessionCellId,
          changes: { [data.key]: data.value },
        },
      );
    },
    deleteDisplayTableSessionState(
      state,
      {
        payload: { data },
      }: AppSessionAction<{
        appSessionCellId: AppSessionCellId;
        deletedDate: DateTimeString;
      }>,
    ) {
      displayTableSessionStateAdapter.updateOne(
        state.displayTableSessionStates,
        {
          id: data.appSessionCellId,
          changes: {
            deletedDate: data.deletedDate,
          },
        },
      );
    },
    // filter cell session state actions
    upsertFilterCellSessionState(
      state,
      action: AppSessionAction<FilterCellSessionStateMP>,
    ) {
      filterCellSessionStateAdapter.upsertOne(
        state.filterCellSessionStates,
        action.payload.data,
      );
    },
    setFilterCellSessionStateField(
      state,
      {
        payload: { data },
      }: AppSessionAction<{
        appSessionCellId: AppSessionCellId;
        key: KeysOfUnion<FilterCellSessionStateMP>;
        value: unknown;
      }>,
    ) {
      filterCellSessionStateAdapter.updateOne(state.filterCellSessionStates, {
        id: data.appSessionCellId,
        changes: { [data.key]: data.value },
      });
    },
    deleteFilterCellSessionState(
      state,
      {
        payload: { data },
      }: AppSessionAction<{
        appSessionCellId: AppSessionCellId;
        deletedDate: DateTimeString;
      }>,
    ) {
      filterCellSessionStateAdapter.updateOne(state.filterCellSessionStates, {
        id: data.appSessionCellId,
        changes: {
          deletedDate: data.deletedDate,
        },
      });
    },
    // shared filter session state actions
    upsertSharedFilterSessionState(
      state,
      action: AppSessionAction<SharedFilterSessionStateMP>,
    ) {
      sharedFilterSessionStateAdapter.upsertOne(
        state.sharedFilterSessionStates,
        action.payload.data,
      );
    },
    setSharedFilterSessionState(
      state,
      {
        payload: { data },
      }: AppSessionAction<{
        sharedFilterSessionStateId: SharedFilterSessionStateId;
        key: KeysOfUnion<SharedFilterSessionStateMP>;
        value: unknown;
      }>,
    ) {
      sharedFilterSessionStateAdapter.updateOne(
        state.sharedFilterSessionStates,
        {
          id: data.sharedFilterSessionStateId,
          changes: { [data.key]: data.value },
        },
      );
    },
    deleteSharedFilterSessionState(
      state,
      {
        payload: { data },
      }: AppSessionAction<{ id: SharedFilterSessionStateId }>,
    ) {
      sharedFilterSessionStateAdapter.updateOne(
        state.sharedFilterSessionStates,
        {
          id: data.id,
          changes: {
            deletedDate: getDateTimeString(new Date()),
          },
        },
      );
    },
    // kernel actions
    setKernelField(state, action: SetFieldAction<KernelMP>) {
      // initialize the kernel if it doesn't exist yet
      if (state.kernel == null) {
        state.kernel = {
          __typename: "Kernel",
          id: state.appSession.id as unknown as KernelId,
          kernelVersion: null,
          sidecarVersion: null,
          state: null,
          expires: null,
          kernelSize: null,
          kernelImage: null,
          kernelSourceImageId: null,
          isActiveKernelSourceVersion: null,
          fileDownloadState: null,
        };
      }

      const kernel: KernelMP = state.kernel;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (kernel as Record<string, any>)[action.payload.data.key] =
        action.payload.data.value;
    },
    // parameter value actions
    upsertParameterValue(state, action: AppSessionAction<ParameterValueMP>) {
      parameterValueAdapter.upsertOne(
        state.parameterValues,
        action.payload.data,
      );
    },
    setParameterValueField(
      state,
      { payload: { data } }: SetEntityFieldAction<ParameterValueMP>,
    ) {
      parameterValueAdapter.updateOne(state.parameterValues, {
        id: data.entityId,
        changes: { [data.key]: data.value },
      });
    },
    deleteParameterValue(
      state,
      {
        payload: { data },
      }: AppSessionAction<{
        parameterValueId: ParameterValueId;
        deletedDate: DateTimeString;
      }>,
    ) {
      parameterValueAdapter.updateOne(state.parameterValues, {
        id: data.parameterValueId,
        changes: {
          deletedDate: data.deletedDate,
        },
      });
    },
    // scope actions
    setAllScopeItems(state, action: AppSessionAction<ScopeItemMP[]>) {
      scopeAdapter.setAll(state.scope, action.payload.data);
    },
    upsertScopeItem(state, action: AppSessionAction<ScopeItemMP>) {
      scopeAdapter.upsertOne(state.scope, action.payload.data);
    },
    deleteScopeItem(
      state,
      {
        payload: { data },
      }: AppSessionAction<{
        scopeItemName: string;
      }>,
    ) {
      scopeAdapter.removeOne(state.scope, data.scopeItemName);
    },
    upsertFile(state, action: AppSessionAction<AppSessionFileMP>) {
      appSessionFilesAdapter.upsertOne(state.files, action.payload.data);
    },
    // file actions
    setFileField(
      state,
      { payload: { data } }: SetEntityFieldAction<AppSessionFileMP>,
    ) {
      appSessionFilesAdapter.updateOne(state.files, {
        id: data.entityId,
        changes: { [data.key]: data.value },
      });
    },
  },
});

//#region selectors
// We wrap each function that creates selectors in `memoize`. This has a few benefits:
//   - We don't have to construct a whole new object of selectors each call
//   - And more importantly, you get back the same selector instances each time you call it,
//     which means that the cache for reselect selectors will work globally and thus will only
//     have to run the computation one time (instead of once per component)
/* eslint-disable @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type */
export const appSessionMPSelectors = {
  getAppSessionSelectors: memoize((appSessionId: AppSessionId) => ({
    select: (state: RootState): AppSessionMP => {
      const appSession = state.appSessionMP[appSessionId]?.appSession;
      assertNonNull(
        appSession,
        `Can't select non-existent app session of id ${appSessionId}`,
      );
      return appSession;
    },
    safeSelect: (state: RootState): AppSessionMP | null =>
      state.appSessionMP[appSessionId]?.appSession ?? null,
  })),

  getAppSessionCellSelectors: memoize((appSessionId: AppSessionId) => {
    const baseSelectors = getSelectorsForEntityStateWithSoftDelete(
      appSessionCellAdapter,
      (state) => state.appSessionMP[appSessionId]?.appSessionCells,
    );

    return {
      ...baseSelectors,
      selectCellIdToAppSessionCell: createDraftSafeSelector(
        baseSelectors.selectAll,
        (appSessionCells) =>
          Object.fromEntries((appSessionCells ?? []).map((a) => [a.cellId, a])),
      ),
      selectCellIdToAppSessionCellId: createDraftSafeSelector(
        baseSelectors.selectAll,
        (appSessionCells) =>
          Object.fromEntries(
            (appSessionCells ?? []).map((a) => [a.cellId, a.id]),
          ),
      ),
    };
  }),

  getChartCellSessionStateSelectors: memoize((appSessionId: AppSessionId) => ({
    selectByAppSessionCellId: (
      state: RootState,
      appSessionCellId: AppSessionCellId,
    ) => {
      const data =
        state.appSessionMP[appSessionId]?.chartCellSessionStates.entities[
          appSessionCellId
        ];
      return data == null || data.deletedDate != null ? null : data;
    },
    selectByAppSessionCellIdWithDeleted: (
      state: RootState,
      appSessionCellId: AppSessionCellId,
    ) =>
      state.appSessionMP[appSessionId]?.chartCellSessionStates.entities[
        appSessionCellId
      ] ?? null,
  })),

  getDisplayTableSessionStateSelectors: memoize(
    (appSessionId: AppSessionId) => ({
      selectByAppSessionCellId: (
        state: RootState,
        appSessionCellId: AppSessionCellId,
      ) => {
        const data =
          state.appSessionMP[appSessionId]?.displayTableSessionStates.entities[
            appSessionCellId
          ];
        return data == null || data.deletedDate != null ? null : data;
      },
      selectByAppSessionCellIdWithDeleted: (
        state: RootState,
        appSessionCellId: AppSessionCellId,
      ) =>
        state.appSessionMP[appSessionId]?.displayTableSessionStates.entities[
          appSessionCellId
        ] ?? null,
      selectHasDisplayTableFilters: (state: RootState) => {
        const data =
          state.appSessionMP[appSessionId]?.displayTableSessionStates
            .entities ?? {};
        for (const displayTableSessionState of Object.values(data)) {
          if (
            displayTableSessionState &&
            displayTableSessionState.deletedDate == null &&
            displayTableSessionState.additionalFilters &&
            displayTableSessionState.additionalFilters.length > 0
          ) {
            return true;
          }
        }
        return false;
      },
    }),
  ),

  getFilterCellSessionStateSelectors: memoize((appSessionId: AppSessionId) => ({
    selectByAppSessionCellId: (
      state: RootState,
      appSessionCellId: AppSessionCellId,
    ) => {
      const data =
        state.appSessionMP[appSessionId]?.filterCellSessionStates.entities[
          appSessionCellId
        ];
      return data == null || data.deletedDate != null ? null : data;
    },
    selectByAppSessionCellIdWithDeleted: (
      state: RootState,
      appSessionCellId: AppSessionCellId,
    ) =>
      state.appSessionMP[appSessionId]?.filterCellSessionStates.entities[
        appSessionCellId
      ] ?? null,
    selectHasFilterCellSessionState: (state: RootState) => {
      const data =
        state.appSessionMP[appSessionId]?.filterCellSessionStates.entities ??
        {};
      for (const filterCellSessionState of Object.values(data)) {
        if (
          filterCellSessionState &&
          filterCellSessionState.deletedDate == null &&
          ((filterCellSessionState.filtersOverride &&
            filterCellSessionState.filtersOverride.filters.length > 0) ||
            filterCellSessionState.filterTypeOverride)
        ) {
          return true;
        }
      }
      return false;
    },
  })),

  getSharedFilterSessionStateSelectors: memoize(
    (appSessionId: AppSessionId) => {
      return getSelectorsForEntityStateWithSoftDelete(
        sharedFilterSessionStateAdapter,
        (state) => state.appSessionMP[appSessionId]?.sharedFilterSessionStates,
      );
    },
  ),

  getKernelSelectors: memoize((appSessionId: AppSessionId) => ({
    // no `select` method because `Kernel` commonly might be `null` and we want to force handling of that
    safeSelect: (state: RootState): KernelMP | null =>
      state.appSessionMP[appSessionId]?.kernel ?? null,
  })),

  getParameterValueSelectors: memoize((appSessionId: AppSessionId) => {
    const baseSelectors = getSelectorsForEntityStateWithSoftDelete(
      parameterValueAdapter,
      (state) => state.appSessionMP[appSessionId]?.parameterValues,
    );
    return {
      ...baseSelectors,
      selectHasParameterValues: (state: RootState) => {
        const data =
          state.appSessionMP[appSessionId]?.parameterValues.entities ?? {};
        return Object.values(data).some(
          (parameterValue) =>
            parameterValue && parameterValue.deletedDate == null,
        );
      },
    };
  }),

  getScopeSelectors: memoize((appSessionId: AppSessionId) => {
    const selectors = getSelectorsForEntityState(
      scopeAdapter,
      (state) => state.appSessionMP[appSessionId]?.scope,
    );
    return {
      // rename selector to make it clear that it operators off of the `name` value
      selectByName: selectors.selectById,
      selectAll: selectors.selectAll,
      selectEntitiesWithHidden: selectors.selectEntities,
      selectEntities: createDraftSafeSelector(
        selectors.selectEntities,
        (entities) =>
          entities == null
            ? null
            : omitBy(entities, (value) => value?.isHidden),
      ),
    };
  }),

  getFileSelectors: memoize((appSessionId: AppSessionId) =>
    getSelectorsForEntityState(
      appSessionFilesAdapter,
      (state) => state.appSessionMP[appSessionId]?.files,
    ),
  ),
};
/* eslint-enable @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type */

//#region full multi-AppSessionMP reducer
type AppSessionMPState = {
  [appSessionId: string]: AppSessionMPValue | undefined;
};

export const appSessionMPActions = {
  ...appSessionMPValueSlice.actions,
};

const allActionTypes = new Set(
  // eslint-disable-next-line tree-shaking/no-side-effects-in-initialization
  Object.values(appSessionMPActions).map((a) => a.type),
);

export const appSessionMPReducer = createReducer<AppSessionMPState>(
  {},
  (builder) =>
    builder.addMatcher(
      (action): action is AppSessionAction<unknown> =>
        allActionTypes.has(action.type),
      (state, action) => {
        state[action.payload.appSessionId] = castDraft(
          appSessionMPValueSlice.reducer(
            state[action.payload.appSessionId],
            action,
          ),
        );
      },
    ),
);
